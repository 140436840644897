
import { Events } from "@/api";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({ components: { ValidationObserver, ValidationProvider } })
export default class ScheduleQualificationForm extends Vue {
  @Prop()
  eventId!: string;
  @Prop()
  address!: string;
  @Prop()
  eventStart!: string;
  @Prop()
  eventDuration!: number;

  currentDate = moment().format("YYYY-MM-DD");

  loading = false;
  saving = false;
  menu1 = false;
  menu2 = false;
  menu3 = false;
  menu4 = false;

  lockedDates: string[][] = [];

  initialQualification: {
    title: string;
    name: string;
    hourFrom: string;
    hourTo: string;
    dateFrom: string;
    dateTo: string;
    address: string;
  } = {
    title: "",
    address: this.address,
    name: "",
    hourFrom: "10:00",
    hourTo: "17:00",
    dateFrom: "",
    dateTo: ""
  };
  qualification: any = { ...this.initialQualification };

  get courseEndDate() {
    return moment(this.eventStart, "DD-MM-YYYY")
      .add(this.eventDuration || 1, "days")
      .format("YYYY-MM-DD");
  }
  get finishDateFormatted() {
    return this.qualification?.dateTo
      ? moment(this.qualification.dateTo).format("MM/DD/YYYY")
      : "";
  }
  get startDateFormatted() {
    return this.qualification?.dateFrom
      ? moment(this.qualification.dateFrom).format("MM/DD/YYYY")
      : "";
  }
  get datesFilled() {
    return (
      !!this.qualification?.hourFrom &&
      !!this.qualification?.hourTo &&
      !!this.qualification?.dateFrom &&
      !!this.qualification?.dateTo
    );
  }

  @Watch("qualification.dateFrom")
  dateFromHandler() {
    this.qualification.dateTo = this.qualification.dateFrom;
  }

  dateInLockedRange(val: Date) {
    return this.lockedDates.some(range => {
      return (
        moment(val).isSameOrAfter(moment(range[0]).startOf("day")) &&
        moment(val).isSameOrBefore(moment(range[1]).endOf("day"))
      );
    });
  }
  allowedStartDates(val: any) {
    // return moment(this.qualification.dateFrom).isSameOrBefore(moment(val));
    if (this.lockedDates.length) {
      if (this.dateInLockedRange(val)) return false;
    }
    return true;
  }
  allowedFinishDates(val: any) {
    const isAfterStartDay = moment(val).isSameOrAfter(
      moment(this.qualification.dateFrom)
    );

    if (!isAfterStartDay) return false;

    return !this.dateInLockedRange(val);
  }

  // onCloseModal() {
  //   this.close();
  // }
  close() {
    this.qualification = { ...this.initialQualification };
    this.$modal.hide("schedule-qualification-modal");
    this.$emit("close");
  }
  async save() {
    try {
      this.saving = true;
      // const delegate = await Delegates.create(data);
      const timeFrom = this.qualification.hourFrom.split(":");
      const timeTo = this.qualification.hourTo.split(":");

      const qualification = {
        ...JSON.parse(JSON.stringify(this.qualification))
      };

      const data = {
        // address: qualification.address,
        title: qualification.name,
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_id: this.eventId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        started_at: moment(qualification.dateFrom)
          .set("hour", timeFrom[0])
          .set("minute", timeFrom[1])
          .toISOString(),
        // .format("DD-MM-YYYYTHH:mm")
        // eslint-disable-next-line @typescript-eslint/camelcase
        finished_at: moment(qualification.dateTo)
          .set("hour", timeTo[0])
          .set("minute", timeTo[1])
          .toISOString()
      };

      await Events.createEventOfflineTraining(data);

      this.close();
      this.$emit("created", {
        ...data,
        id: `id_${Date.now()}`,
        delegates: [],
        // eslint-disable-next-line @typescript-eslint/camelcase
        startedAt: qualification.dateFrom,
        // eslint-disable-next-line @typescript-eslint/camelcase
        finishedAt: qualification.dateTo
      });
      await this.$success(
        "<strong>Success!</strong> Qualification has been created!"
      );
    } catch (e) {
      const err = e as any;
      (this.$refs.delegateForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(err.errors);
      await this.$error(err.errors);
    } finally {
      this.saving = false;
    }
  }

  async created() {
    if (this.eventId) {
      this.lockedDates = await Events.getEventOfflineTrainingsLockedDates(
        this.eventId
      );
    }
  }
}
