
import { Events } from "@/api";
import { OfflineTraining } from "@/types/offlineTraining";
import { formateDate } from "@/utils/helpers";
import moment from "moment";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import PageTitle from "../common/PageTitle.vue";
import ScheduleQualificationForm from "./ScheduleQualificationForm.vue";

@Component({
  components: { PageTitle, ScheduleQualificationForm },
  methods: { formateDate }
})
export default class ScheduleQualification extends Vue {
  @Prop()
  eventId!: string;
  @Prop()
  address!: string;
  @Prop()
  eventStart!: string;
  @Prop()
  eventDuration!: number;
  @Prop()
  offlineTrainings!: OfflineTraining[];
  @Prop({ default: false })
  viewMode!: boolean;

  formateDate: any;

  headersTitle = [
    {
      icon: "mdi-calendar-month",
      header: "Offline Training Schedule For Qualifications"
    }
  ];
  headerItems = [
    {
      text: "Title",
      value: "title"
    },
    // {
    //   text: "Address",
    //   value: "address"
    // },
    {
      text: "Start Date",
      value: "startedAt"
    },
    {
      text: "End Date",
      value: "finishedAt"
    }
  ];

  get headers() {
    const headers: any = [...this.headerItems];

    if (!this.viewMode) {
      headers.push({
        text: "Action",
        value: "actions",
        align: "center"
      });
    }

    return headers;
  }

  isScheduleModalOpen = false;

  get sortedByDateOfflineTrainings() {
    return this.offlineTrainings.sort((a, b) =>
      moment(a.started_at).isAfter(b.started_at) ? -1 : 1
    );
  }

  openScheduleModal() {
    this.isScheduleModalOpen = true;
    this.$nextTick(() => {
      this.$modal.show("schedule-qualification-modal");
    });
  }
  onClose() {
    this.isScheduleModalOpen = false;
  }
  onCreated(data: any) {
    this.$emit("created", data);
  }
  // async onDownloadRegister(item: any) {
  //   try {
  //     console.log("item: ", item);

  //     // await Events.downloadOfflineTrainingRegister(id);
  //     // this.$success(
  //     //   " have been downloaded!"
  //     // );
  //   } catch (e) {
  //     const err = e as any;
  //     await this.$error(
  //       { err: err.result?.message } || err.errors || "Something went wrong"
  //     );
  //   }
  // }
  async onDelete(id: number) {
    const res = await this.$dialog.error({
      text: "Do you really want to delete qualification?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      try {
        if (!String(id).startsWith("id_")) {
          await Events.deleteEventOfflineTraining(id);
        }
        this.$success(
          "<strong>Success!</strong> Qualification has been deleted!"
        );
        this.$emit("deleted", id);
      } catch (e) {
        const err = e as any;
        await this.$error(
          { err: err.result?.message } || err.errors || "Something went wrong"
        );
      }
    }
  }
}
